const replace = {
  changeSMID: 'Volpro ID ändern',
  reqSMID: 'Volpro ID *',
  SMID: 'Volpro ID',
  SMIDTolltip: 'Die Volpro ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'Volpro ID',
  smidLabel: 'Volpro ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die Volpro -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der Volpro-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
