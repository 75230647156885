const replace = {
  changeSMID: 'Ændre Volpro ID',
  reqSMID: 'Volpro ID *',
  SMID: 'Volpro ID',
  SMIDTolltip: 'The Volpro ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: 'Volpro ID',
  smidLabel: 'Volpro ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste Volpro ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med Volpro-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
