const replace = {
  changeSMID: 'Cambia l\'ID Volpro OEM',
  reqSMID: 'Volpro ID *',
  SMID: 'Volpro ID',
  SMIDTolltip: 'Il Volpro ID è un identificatore unico del tuo dispositivo. Questo ID si trova sul dispositivo sotto il codice QR.',
  enableSmId: 'Volpro ID',
  smidLabel: 'Volpro ID',
  confirmDeletingDesc: 'Conferma la cancellazione di tutti i dati di questo gateway inserendo l\'ID Volpro. Dopo la conferma, questo processo non può essere invertito.',
  confirmDeletingError: 'L\'ID non corrisponde all\'ID Volpro. Il gateway non è stato cancellato.'
};

export default replace;
