export const themeMui = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#FFE300'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#FFE300',
      dark: '#293033'
    },
    secondary: {
      main: '#FFF',
      dark: '#8F939B',
      lowTariff: '#1BBC9B',
      highTariff: '#DE3F1A',
      stepColor: 'rgba(255, 255, 255, 0.5)',
      stepSubtitleColor: '#959BA2',
      blockBackgroundLight: '#2E373B'
    }
  },
  typography: {
    h2: {
      fontSize: 20,
      fontWeight: 600
    },
    h3: {
      fontSize: 18,
      fontWeight: 600
    },
    h4: {
      fontSize: 16,
      fontWeight: 400
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Roboto Condensed, sans-serif'].join(',')
  }
};
