const replace = {
  changeSMID: 'Change Volpro ID',
  reqSMID: 'Volpro ID *',
  SMID: 'Volpro ID',
  SMIDTolltip: 'The Volpro ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'Volpro ID',
  smidLabel: 'Volpro ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the Volpro ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the Volpro ID. The gateway is not deleted.'
};

export default replace;
