export const multiselectConfig = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px'
  },
  searchBox: {
    width: '100%',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    backgroundColor: 'transparent',
    borderRadius: '8px'
  },
  chips: {
    backgroundColor: '#fff',
    whiteSpace: 'normal',
    color: '#000'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: '#293033',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    top: '4px'
  },
  inputField: {
    maxWidth: '240px'
  }
};

export const READ_ONLY_ROLES = ['pv_installer_employee_read_only', 'oem_employee_read_only'];
